import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro'
import { Typography } from '@mui/material'
import { Table } from '../../../components/Table'
import { AppThunkDispatch, RootState } from '../../../redux'
import { deviceUpgradeMonitoring } from '../../../redux/upgradeMonitoring/reducers'
import { ProgressBar } from '../../../components/ProgressBar'
import { DeviceMonitoring } from '../../../models/upgradeMonitoring/deviceMonitoring'
import { GreenSuccess } from '../../../common/constants/colorConstants'

function mapDispatchToProps(dispatch: AppThunkDispatch) {
  return {
    dispatchGetDevices: (...args: Parameters<typeof deviceUpgradeMonitoring>) => dispatch(deviceUpgradeMonitoring(...args))
  }
}

function mapStateToProps(state: RootState) {
  return {
    user: state.user,
    company: state.company,
    selectedSite: state.upgradeMonitoring.selectedSite,
    devicesRequestState: state.upgradeMonitoring.deviceUpgradeMonitoringState,
    devices: state.upgradeMonitoring.devices,
    refreshRate: state.upgradeMonitoring.refreshRate
  }
}

type DispatchProps = ReturnType<typeof mapDispatchToProps>
type StateProps = ReturnType<typeof mapStateToProps>

type deviceMonitoringTableProps = DispatchProps & StateProps

const columns: GridColDef[] = [
  {
    field: 'id',
    headerName: 'Device ID',
    flex: 1,
    hide: true
  },
  {
    field: 'deviceName',
    headerName: 'Device Name',
    flex: 1
  },
  {
    field: 'deviceType',
    headerName: 'Device Type',
    flex: 1
  },
  {
    field: 'downloadProgress',
    headerName: 'Download Progress',
    flex: 1,
    renderCell: (params: GridRenderCellParams) => params.row.downloadProgress != null && <ProgressBar value={params.row.downloadProgress} />
  },
  {
    field: 'upgradeProgress',
    headerName: 'Upgrade Progress',
    flex: 1,
    renderCell: (params: GridRenderCellParams) => params.row.upgradeProgress != null && <ProgressBar value={params.row.upgradeProgress} />
  },
  {
    field: 'isBusinessReady',
    headerName: 'Ready for Business',
    flex: 1,
    minWidth: 150,
    renderCell: (params: GridRenderCellParams) => params.row.isBusinessReady && (<Typography sx={{ color: GreenSuccess }}><strong>Device Ready</strong></Typography>)
  },
  {
    field: 'state',
    headerName: 'State',
    flex: 1
  },
  {
    field: 'checkpoint',
    headerName: 'Checkpoint',
    flex: 1
  }
]

export function DeviceMonitoringTable(
  {
    user,
    devicesRequestState,
    dispatchGetDevices,
    selectedSite,
    devices,
    refreshRate,
    company
  } : deviceMonitoringTableProps
): JSX.Element {
  useEffect(() => {
    if (
      user.dataCenter
      && user.userId
      && user.sessionId
      && selectedSite
      && selectedSite.siteId
    ) {
      const { userId, sessionId, dataCenter } = user
      dispatchGetDevices({
        userId,
        sessionId,
        dataCenter,
        siteId: selectedSite.siteId
      })
    }
  }, [selectedSite?.siteId])

  useEffect(() => {
    if (refreshRate.value > 0) {
      const intervalId = setInterval(() => {
        if (user.dataCenter && user.userId && user.sessionId && selectedSite && selectedSite.siteId) {
          const { userId, sessionId, dataCenter } = user
          dispatchGetDevices({
            userId,
            sessionId,
            dataCenter,
            siteId: selectedSite.siteId
          })
        }
      }, refreshRate.value)
      return () => clearInterval(intervalId)
    }
    return undefined
  }, [refreshRate, company.selectedCompany?.id])

  const rows = devices.map((d: DeviceMonitoring) => ({
    id: d.deviceId,
    deviceName: d.deviceName,
    deviceType: d.deviceType,
    downloadProgress: d.downloadPercent,
    upgradeProgress: d.upgradePercent,
    isBusinessReady: d.isBusinessReady,
    state: d.agentState,
    checkpoint: d.checkpoint
  }))

  return (
    <Table
      width="100%"
      rows={devicesRequestState === 'pending' ? [] : rows}
      columns={columns}
      rowsToShow={5}
      rowsToShowOptions={[5, 10, 20]}
      toolbar
      loading={devicesRequestState === 'pending'}
      disableSelectionOnClick
    />
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(DeviceMonitoringTable)
