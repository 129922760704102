import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Stack, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import NCRApprovedSolutionSetsTable from './components/NCRApprovedSolutionSetsTable'
import SavedSolutionSetsTable from './components/SavedSolutionSetsTable'
import { AppThunkDispatch, RootState } from '../../redux'
import {
  setRemoveRequestState,
  setSaveRequestState,
  setSelectedSolutionSet,
  setEditableSelectedSolutionSet
} from '../../redux/solutionSets/actions'
import SaveSolutionSetDialog from './components/SaveSolutionSetDialog'
import RemoveSolutionSetDialog from './components/RemoveSolutionSetDialog'
import {
  BasicDrawer,
  ButtonOptions,
  DrawerHeaderOptions
} from '../../components/Drawer'
import SolutionSetSummary from './components/SolutionSetSummary'
import Notification from '../../components/Notification'

function mapDispatchToProps(dispatch: AppThunkDispatch) {
  return {
    dispatchSetSelectedSolutionSet: (
      ...args: Parameters<typeof setSelectedSolutionSet>
    ) => dispatch(setSelectedSolutionSet(...args)),
    dispatchSetEditableSelectedSolutionSet: (
      ...args: Parameters<typeof setEditableSelectedSolutionSet>
    ) => dispatch(setEditableSelectedSolutionSet(...args)),
    dispatchSetRemoveRequestState: (
      ...args: Parameters<typeof setRemoveRequestState>
    ) => dispatch(setRemoveRequestState(...args)),
    dispatchSetSaveRequestState: (
      ...args: Parameters<typeof setSaveRequestState>
    ) => dispatch(setSaveRequestState(...args))
  }
}

function mapStateToProps(state: RootState) {
  return {
    solutionSets: state.solutionSets,
    company: state.company
  }
}

type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type StateProps = ReturnType<typeof mapStateToProps>;
type SolutionSetsPageProps = DispatchProps & StateProps;

export function SolutionSetsPage({
  company,
  solutionSets,
  dispatchSetSelectedSolutionSet,
  dispatchSetEditableSelectedSolutionSet,
  dispatchSetRemoveRequestState,
  dispatchSetSaveRequestState
}: SolutionSetsPageProps) {
  // Solution Set Alert Dialog set up
  const navigate = useNavigate()
  const [saveDialogOpen, setSaveDialogOpen] = useState(false)
  const [removeDialogOpen, setRemoveDialogOpen] = useState(false)

  // Determines whether the title should include extra wording for when hitting the deploy buttons
  // as opposed to the save buttons
  const [dialogTitleVariant, setDialogTitleVariant] = useState<
    'save' | 'deploy'
  >('save')

  // Solution Sets Summary Drawer set up
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [ssTypeSelected, setSSTypeSelected] = useState<'NCR' | 'saved' | null>(
    null
  )
  const [ncrDeploy, setNcrDeploy] = useState(false)
  const [ncrName, setNcrName] = useState('')
  // Should only show the save button in the drawer for
  // NCR approved solution sets, not already saved ones
  let headerOptions: DrawerHeaderOptions | undefined
  if (ssTypeSelected === 'NCR') {
    headerOptions = {
      enableCloseArrow: true,
      moreOptions: [
        {
          label: 'Edit',
          onClick: () => {
            dispatchSetEditableSelectedSolutionSet(
              solutionSets.selectedSolutionSet
            )
            navigate('/solutionsets/edit')
          },
          variant: 'text'
        }
      ],
      buttons: [
        {
          label: 'Save',
          onClick: () => {
            setSaveDialogOpen(true)
            setDialogTitleVariant('save')
          },
          variant: 'outlined'
        },
        {
          label: 'Deploy',
          onClick: () => {
            setSaveDialogOpen(true)
            setDialogTitleVariant('deploy')
          },
          variant: 'contained'
        }
      ]
    }
    headerOptions.moreOptions = [
      ...(headerOptions.moreOptions as ButtonOptions[]),
      ...(headerOptions.buttons as ButtonOptions[])
    ]
  } else if (ssTypeSelected === 'saved') {
    headerOptions = {
      enableCloseArrow: true,
      moreOptions: [
        {
          label: 'Remove',
          onClick: () => {
            setRemoveDialogOpen(true)
          },
          variant: 'contained'
        }
      ],
      buttons: [
        {
          label: 'Deploy',
          onClick: () => {
            // TODO: Bring to deploy screen
          },
          variant: 'contained'
        }
      ]
    }
  }

  useEffect(() => {
    if (company.selectedCompany === null) {
      navigate('/select-company')
    }

    setDrawerOpen(false)
    setSaveDialogOpen(false)
    setDialogTitleVariant('save')
    dispatchSetSelectedSolutionSet(null)
  }, [])

  useEffect(() => {
    if (ncrDeploy) {
      const solName = ncrName
      dispatchSetSelectedSolutionSet(
        solutionSets.availableSavedSolutionSets.filter(
          (sol) => sol.name === solName
        )[0]
      )
      navigate('/deployments?solSet=true')
    }
  }, [solutionSets.availableSavedSolutionSets])

  const setSaveNcrOpenAndNavigate = (value: boolean, name: string) => {
    setSaveDialogOpen(value)
    setNcrDeploy(true)
    setNcrName(name)
  }

  return (
    <>
      {/* Notification for when a solution set is successfully removed */}
      <>
        <Notification
          open={solutionSets.removeRequestState === 'fulfilled'}
          onClose={() => dispatchSetRemoveRequestState('idle')}
          severity="success"
          message="Solution set removed"
          data-testid="notification-solution-set-removed-success"
        />
        <Notification
          open={solutionSets.removeRequestState === 'rejected'}
          onClose={() => {
            dispatchSetRemoveRequestState('idle')
          }}
          severity="error"
          message="Unable to remove solution set"
          data-testid="notification-solution-set-removed-error"
        />
        {/* Notification for when a solution set is saved */}
        <Notification
          open={solutionSets.saveRequestState === 'fulfilled'}
          onClose={() => dispatchSetSaveRequestState('idle')}
          severity="success"
          message="Solution set saved"
          data-testid="notification-solution-set-saved-success"
        />
        <Notification
          open={solutionSets.saveRequestState === 'rejected'}
          onClose={() => {
            dispatchSetSaveRequestState('idle')
          }}
          severity="error"
          message="Unable to save solution set"
          data-testid="notification-solution-set-saved-error"
        />
      </>

      {/* Dialog Popups */}
      <>
        <SaveSolutionSetDialog
          open={saveDialogOpen}
          setOpen={setSaveNcrOpenAndNavigate}
          titleVariant={dialogTitleVariant}
        />
        <RemoveSolutionSetDialog
          open={removeDialogOpen}
          onClose={() => {
            setRemoveDialogOpen(false)
            setDrawerOpen(false)
            dispatchSetSelectedSolutionSet(null)
          }}
        />
      </>

      {/* Page Content for Solution Sets */}
      <Stack
        direction="column"
        alignItems="flex-start"
        spacing={6}
        sx={{
          width: drawerOpen ? 'calc(100% - 350px)' : '100%'
        }}
      >
        {/* NCR-Approved Solution Sets */}
        <Stack
          alignItems="baseline"
          spacing={2}
          sx={{
            width: '100%'
          }}
        >
          <Typography variant="h4">NCR-Approved Solution Sets</Typography>
          <Typography variant="body1">
            Find a new NCR SIT that works for you. Select one to view more
            details, or create a deployment now.
          </Typography>
          <NCRApprovedSolutionSetsTable
            width="100%"
            onCellClick={(params) => {
              const solutionSet = solutionSets.availableNCRSolutionSets.filter(
                (ss) => ss.id === params.row.id
              )[0]

              setSSTypeSelected('NCR')
              if (params.field === 'deploy') {
                dispatchSetSelectedSolutionSet(solutionSet)
                setSaveDialogOpen(true)
                setDialogTitleVariant('deploy')
              } else if (params.field === 'edit') {
                dispatchSetEditableSelectedSolutionSet(solutionSet)
                navigate('/solutionsets/edit')
              } else {
                dispatchSetSelectedSolutionSet(solutionSet)
                setDrawerOpen(true)
              }
            }}
          />
        </Stack>

        {/* Saved Solution Sets */}
        <Stack
          alignItems="baseline"
          spacing={2}
          sx={{
            width: '100%'
          }}
        >
          <Typography variant="h4">Saved Solution Sets</Typography>
          <Typography variant="body1">
            View details about your customized solution sets or deploy them.
          </Typography>
          <SavedSolutionSetsTable
            width="100%"
            onCellClick={(params) => {
              const selectedSolutionSet = solutionSets.availableSavedSolutionSets.filter(
                (ss) => ss.id === params.row.id
              )[0]

              dispatchSetSelectedSolutionSet(selectedSolutionSet)
              setSSTypeSelected('saved')

              // If the remove button was clicked, show the popup to remove
              if (params.field === 'remove') {
                setRemoveDialogOpen(true)
                return
              }

              if (params.field === 'deploy') {
                navigate('/deployments?solSet=true')
                return
              }
              setDrawerOpen(true)
            }}
          />
        </Stack>
      </Stack>

      {/* Drawer */}
      <BasicDrawer
        width={350}
        openDrawer={drawerOpen}
        closeDrawer={() => {
          setDrawerOpen(false)
          dispatchSetSelectedSolutionSet(null)
          setSSTypeSelected(null)
        }}
        drawerHeader={headerOptions}
      >
        {solutionSets.selectedSolutionSet && (
          <SolutionSetSummary solutionSet={solutionSets.selectedSolutionSet} />
        )}
      </BasicDrawer>
    </>
  )
}
export default connect(mapStateToProps, mapDispatchToProps)(SolutionSetsPage)
