import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { TextField } from '@mui/material'
import { v4 } from 'uuid'
import { saveSolutionSet } from '../../../redux/solutionSets/actions'
import { AppThunkDispatch, RootState } from '../../../redux'
import { dateToString } from '../../../utils/date'
import { isUnique } from '../../../utils/solutionSet'

interface OwnProps {
  /** Whether the dialog is open and ready for input */
  open: boolean;
  /** The setter to alter the open state value */
  setOpen: (value: boolean, name: string) => void;
  /** Title variant */
  titleVariant: 'save' | 'deploy';
}

function mapStateToProps(state: RootState) {
  return {
    solutionSets: state.solutionSets,
    user: state.user,
    company: state.company
  }
}

function mapDispatchToProps(dispatch: AppThunkDispatch) {
  return {
    dispatchSaveSolutionSet: (...args: Parameters<typeof saveSolutionSet>) => dispatch(saveSolutionSet(...args))
  }
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type SolutionSetDialogProps = OwnProps & DispatchProps & StateProps;

export function SaveSolutionSetDialog({
  open,
  setOpen,
  user,
  company,
  titleVariant,
  solutionSets,
  dispatchSaveSolutionSet
}: SolutionSetDialogProps) {
  const [name, setName] = useState('') // The name of the solution set to save
  const [uniqueNamePrompt, setUniqueNamePrompt] = useState(false) // Whether or not to show the unique name prompt

  useEffect(() => {
    // Sets the text field to the name of the selected solution set by default
    const ssName = solutionSets.selectedSolutionSet?.name
    if (ssName) {
      setName(ssName)
    } else {
      setName('')
    }
  }, [solutionSets.selectedSolutionSet])

  let title = 'Save Solution Set'
  if (titleVariant === 'deploy') {
    title = title.concat(' before deploying')
  }

  return (
    <div>
      <Dialog
        open={open}
        PaperProps={{
          sx: {
            borderRadius: '4px'
          }
        }}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Save this exact solution set for deployment later, even if new
            versions are released before then. Optionally rename this solution
            stack then press Save.
          </DialogContentText>
          <TextField
            variant="standard"
            fullWidth
            id="name"
            label="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            inputProps={{
              'data-testid': 'save-solution-set-dialog-input-name'
            }}
            helperText={
              uniqueNamePrompt
                ? 'Only able to save a solution set with a unique name'
                : ''
            }
            error={uniqueNamePrompt}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpen(
                false,
                solutionSets.selectedSolutionSet?.name
                  ? solutionSets.selectedSolutionSet.name
                  : ''
              )
              setUniqueNamePrompt(false)
              if (solutionSets.selectedSolutionSet?.name) {
                setName(solutionSets.selectedSolutionSet.name)
              }
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            data-testid="save-solution-set-dialog-confirm-button"
            onClick={() => {
              // Name must be unique when trying to save (case doesn't matter)
              // Test and TEST are considered equal
              const isUniqueName = isUnique(
                name,
                solutionSets.availableSavedSolutionSets
              )
              if (isUniqueName === false) {
                setUniqueNamePrompt(true)
                return
              }

              if (solutionSets.selectedSolutionSet) {
                const newSolutionSet = {
                  ...solutionSets.selectedSolutionSet,
                  id: v4(),
                  name,
                  date: dateToString(new Date())
                }
                if (user.dataCenter && company.selectedCompany?.id && user.sessionId && user.userId) {
                  dispatchSaveSolutionSet(
                    newSolutionSet,
                    user.dataCenter,
                    company.selectedCompany?.id,
                    user.sessionId,
                    user.userId
                  )
                }
              }
              setOpen(false, name)
              setUniqueNamePrompt(false)
              if (solutionSets.selectedSolutionSet?.name) {
                setName(solutionSets.selectedSolutionSet.name)
              }
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SaveSolutionSetDialog)
