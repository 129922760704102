import LoadingButton from '@mui/lab/LoadingButton'
import {
  Button,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent
} from '@mui/material'
import React from 'react'
import { SelectedProduct } from '..'

interface ModalContentForm {
  selectedProduct: SelectedProduct;
  versionValue: string;
  versions: string[];
}

interface ModalActionProps {
  loading: boolean;
  onClickConfirm: React.MouseEventHandler<HTMLButtonElement> | undefined;
  onClickCancel: React.MouseEventHandler<HTMLButtonElement> | undefined;
  disableConfirm: boolean;
}

interface ModalContentProps {
  modalContentForm: ModalContentForm;
  setVersionValue: React.Dispatch<React.SetStateAction<string>>;
}

/** Content component for the second modal of VersionConfirmationDialog; Displays content within the second sequence */
export function SecondVersionModalContent(props: ModalContentProps) {
  const { modalContentForm, setVersionValue } = props
  const { selectedProduct, versionValue } = modalContentForm
  const handleChange = (e: SelectChangeEvent<string>) => {
    setVersionValue(e.target.value)
  }

  return (
    <>
      <InputLabel style={{ fontSize: 14 }} id="label">
        Version
      </InputLabel>
      <Select
        labelId="label"
        id="select"
        defaultValue={selectedProduct.customState.version}
        value={versionValue}
        variant="standard"
        style={{ width: 200, marginTop: '0px' }}
        onChange={handleChange}
      >
        {modalContentForm.versions.map((v: string) => (
          <MenuItem key={v} value={v}>
            {v}
          </MenuItem>
        ))}
      </Select>
    </>
  )
}

/** Action component for the second modal of VersionCOnfirmationDialog; Handles the confirmation/cancellation actions */
export function SecondVersionModalAction(props: ModalActionProps): JSX.Element {
  return (
    <>
      <Button
        variant="text"
        data-testid="choose-version-dialog-action-cancel"
        onClick={props.onClickCancel}
      >
        Cancel
      </Button>
      <LoadingButton
        loading={props.loading}
        disabled={props.disableConfirm}
        variant="contained"
        data-testid="choose-version-dialog-action-confirm"
        onClick={props.onClickConfirm}
      >
        Change
      </LoadingButton>
    </>
  )
}
