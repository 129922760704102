import LoadingButton from '@mui/lab/LoadingButton'
import { Button } from '@mui/material'
import React from 'react'

interface FirstVersionModalActionProps {
  loading: boolean;
  onCancel: React.MouseEventHandler<HTMLButtonElement> | undefined;
  onConfirm: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

/** Action Component for the first modal within the VersionConfirmationDialog; Handles the confirmation/cancellation actions */
export function FirstVersionModalAction(props: FirstVersionModalActionProps) {
  return (
    <>
      <LoadingButton
        variant="contained"
        data-testid="choose-version-dialog-action-confirm"
        loading={props.loading}
        onClick={props.onConfirm}
      >
        I Understand
      </LoadingButton>
      <Button
        variant="text"
        data-testid="choose-version-dialog-action-cancel"
        onClick={props.onCancel}
      >
        Nevermind
      </Button>
    </>
  )
}
