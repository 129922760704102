import React, { useEffect } from 'react'
import { Button, Stack } from '@mui/material'
import {
  GridColDef,
  gridDateComparator,
  GridEventListener,
  GridEvents
} from '@mui/x-data-grid-pro'
import { connect } from 'react-redux'
import { Table } from '../../../components/Table'
import { AppThunkDispatch, RootState } from '../../../redux'
import { getAvailableSavedSolutionSets } from '../../../redux/solutionSets/actions'
import { stringToDate, dateToDisplayString } from '../../../utils/date'

function mapDispatchToProps(dispatch: AppThunkDispatch) {
  return {
    dispatchGetAvailableSavedSolutionSets: (
      ...args: Parameters<typeof getAvailableSavedSolutionSets>
    ) => dispatch(getAvailableSavedSolutionSets(...args))
  }
}

function mapStateToProps(state: RootState) {
  return {
    user: state.user,
    company: state.company,
    solutionSets: state.solutionSets
  }
}

interface OwnProps {
  onCellClick?: GridEventListener<GridEvents.cellClick>;
  width: number | string;
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type SavedSolutionSetsTableProps = OwnProps & StateProps & DispatchProps;

export function SavedSolutionSetsTable({
  width,
  onCellClick,
  solutionSets,
  user,
  company,
  dispatchGetAvailableSavedSolutionSets
}: SavedSolutionSetsTableProps) {
  // Fetch the available NCR Solution Sets on table load once
  useEffect(() => {
    if (user.dataCenter && company.selectedCompany?.id) {
      dispatchGetAvailableSavedSolutionSets()
    }
  }, [company.selectedCompany?.id])

  const rows = solutionSets.availableSavedSolutionSets.map((ss) => ({
    id: ss.id,
    name: ss.name,
    date: stringToDate(ss.date),
    deploy: 'Deploy'
  }))

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      resizable: false,
      flex: 2
    },
    {
      field: 'date',
      headerName: 'Date Saved',
      type: 'date',
      resizable: false,
      flex: 2,
      sortComparator: gridDateComparator,
      sortingOrder: ['desc', 'asc'],
      valueFormatter: (p) => {
        if (p.value instanceof Date) {
          return dateToDisplayString(p.value)
        }
        return p.value
      }
    },
    {
      field: 'remove',
      headerName: '',
      resizable: false,
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      renderCell: () => (
        <Button
          fullWidth
          sx={{
            textDecoration: 'underline',
            '&:hover': {
              textDecoration: 'underline'
            }
          }}
          data-testid="test-remove-saved"
        >
          Remove
        </Button>
      )
    },
    {
      field: 'deploy',
      headerName: '',
      resizable: false,
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      renderCell: () => (
        <Button
          fullWidth
          sx={{
            textDecoration: 'underline',
            '&:hover': {
              textDecoration: 'underline'
            }
          }}
        >
          Deploy
        </Button>
      )
    }
  ]

  return (
    <Stack
      direction="row"
      alignItems="flex-start"
      spacing={2}
      sx={{ width: '100%' }}
    >
      <Table
        width={width}
        rows={
          solutionSets.fetchSavedSolutionSetRequestState === 'pending'
            ? []
            : rows
        }
        columns={columns}
        rowsToShow={5}
        rowsToShowOptions={[5, 10, 20]}
        onCellClick={onCellClick}
        loading={solutionSets.fetchSavedSolutionSetRequestState === 'pending'}
        initialState={{
          sorting: {
            sortModel: [{ field: 'date', sort: 'desc' }]
          }
        }}
      />
    </Stack>
  )
}

SavedSolutionSetsTable.defaultProps = {
  onCellClick: () => {
    // Do nothing
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SavedSolutionSetsTable)
