/**
 * All date related manipulation functions are provided here to prevent
 * inconsistencies and different implementations of date parsing throughout
 * the application.
 *
 * Note that redux will run into issues if a Date object is passed directly
 * to be serialized.
 */

import { Dayjs } from 'dayjs'

/**
 * Converts the string to a Date object. Intended to be used
 * for when Date objects are to be manipulated or compared to another
 * but the Date is given as a string.
 *
 * @returns The date string as a Date object
 */
export function stringToDate(s: string): Date {
  return new Date(s)
}

/**
 * Converts a date object to a string in simplified extended ISO format (ISO 8601),
 * which is always 24 or 27 characters long (YYYY-MM-DDTHH:mm:ss.sssZ or ±YYYYYY-MM-DDTHH:mm:ss.sssZ, respectively).
 * The timezone is always zero UTC offset, as denoted by the suffix Z.
 *
 * Intended to be used when serializing a date into a format for storage.
 *
 * @returns The date object as a string
 */
export function dateToString(d: Date): string {
  return d.toISOString()
}

/**
 * Converts a Date object into a string with the following format
 * Jan 01, 1970
 *
 * Intended to be used for a pretty display of a date.
 *
 * @returns The Date as a display string format
 */
export function dateToDisplayString(d: Date): string {
  return d.toLocaleDateString('default', {
    month: 'short',
    day: '2-digit',
    year: 'numeric'
  })
}

/**
 * Converts a Date object into a string with the following format
 * Thursday Jan 01, 1970
 *
 * Intended to be used for a detailed display of a date.
 *
 * @returns The Date as a display string format
 */
export function dateToDisplayStringDetailed(d: Date): string {
  return d.toLocaleDateString('default', {
    weekday: 'long',
    month: 'short',
    day: '2-digit',
    year: 'numeric'
  })
}

/**
 * Converts a Date string into a string with the following format
 * Jan 01, 1970
 *
 * Intended to be used for a pretty display of a date.
 *
 * @returns The Date as a display string format
 */
export function dateStringToDisplayString(d: string): string {
  return dateToDisplayString(stringToDate(d))
}
/**
 * Checks for past date
 *
 * Intended to be used for checking the past date
 *
 * @returns The Date as a display string format
 */
export function isPastDate(date: Dayjs) {
  const todayDate = new Date()
  todayDate.setDate(todayDate.getDate() - 1)
  return date.isBefore(todayDate)
}
/**
 * Converts a Time string into a string with the following format
 * 35:50 as 11:50am
 *
 * Intended to be used for a pretty display of a time.
 *
 * @returns The Time as a am/pm format
 */
export function getLocalTime(eodTime: string | null): string {
  let localEODTime = ''
  let ampm = ''

  if (eodTime) {
    const timeAry = eodTime.split(':')
    let firstHand = parseInt(timeAry[0], 10)

    if (firstHand >= 24) firstHand %= 24
    if (firstHand >= 12) {
      ampm = 'pm'
      if (firstHand > 12) firstHand %= 12
    } else {
      ampm = 'am'
    }

    localEODTime = `${firstHand}:${timeAry[1]}${ampm}`
  }

  return localEODTime
}
