import React from 'react'
import {
  Alert, AlertColor, IconButton, Snackbar
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

export interface NotificationProps {
  /** The condition to which this notification will open */
  open: boolean;
  /** What to do when this notification is closed */
  onClose: (() => void) | undefined;
  /** The severity color of this notification */
  severity: AlertColor;
  /** The message to display on this notification */
  message: string;
  /** The data-testid attached to this notification component */
  'data-testid': string;
}

/**
 * Component to display an Snackbar Alert
 */
export default function Notification(props: NotificationProps) {
  const {
    open, onClose, severity, message
  } = props
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={open}
      onClose={onClose}
      autoHideDuration={5000}
      action={(
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={onClose}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      )}
      // eslint-disable-next-line react/destructuring-assignment
      data-testid={props['data-testid']}
    >
      <Alert
        variant="filled"
        onClose={onClose}
        severity={severity}
        sx={{
          width: '50vw',
          borderRadius: 0,
          boxShadow:
            '0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.14), 0px 1px 8px rgba(0, 0, 0, 0.12)'
        }}
      >
        {message}
      </Alert>
    </Snackbar>
  )
}
