/**
 * Receives and tests a regex value and returns the first match if regex test passes; returns a default value if regex fails
 * @param regex the Regaular Expression pattern
 * @param testValue the string value to valid
 * @returns the first resulting matching regex pattern or test value if unsuccessful
 */
export function getRegexOrDefaultValue(
  regex: RegExp,
  testValue: string
): string {
  const testedExp = regex.exec(testValue)
  if (testedExp && testedExp.length > 0) {
    return testedExp[0]
  }
  return testValue
}

/**
 * Receives aloha product friendlyName and converts it to the 3 letter shortHandName
 * @param friendlyName
 */
export function shortHandName(friendlyName: string) {
  return getRegexOrDefaultValue(/(?<=\()[A-Z]{2,3}/, friendlyName)
}
