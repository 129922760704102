import React from 'react'
import { Stack, Typography } from '@mui/material'
import { SolutionSet, getProductAcronym } from '../../../../data/solutionSet'

/**
 * Takes a Solution Set data object and displays it inside a drawer
 */
export default function SolutionSetSummary({
  solutionSet
}: {
  solutionSet: SolutionSet;
}): JSX.Element {
  return (
    <Stack
      direction="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={4}
      paddingLeft={1.5}
      paddingTop={2}
      paddingRight={3}
    >
      <Typography variant="h5">{solutionSet.name}</Typography>

      <Stack spacing={0}>
        <Typography variant="caption" color="rgba(0, 0, 0, 0.6)">
          Description
        </Typography>
        <Typography variant="body1">{solutionSet.description}</Typography>
      </Stack>

      <Stack spacing={0}>
        <Typography variant="caption" color="rgba(0, 0, 0, 0.6)">
          Type
        </Typography>
        <Typography variant="body1">{solutionSet.type}</Typography>
      </Stack>

      <Stack>
        <Typography variant="caption" color="rgba(0, 0, 0, 0.6)">
          Contents
        </Typography>
        <Stack paddingLeft={1}>
          {solutionSet.products.map((product) => (
            <Typography variant="body1" key={product.name}>
              {`• ${getProductAcronym(product.name)} (v${product.version})`}
            </Typography>
          ))}
        </Stack>
      </Stack>

      <Stack spacing={0}>
        <Typography variant="caption" color="rgba(0, 0, 0, 0.6)">
          Solution Guides
        </Typography>
        <Stack spacing={3}>
          <Typography
            sx={{
              cursor: 'pointer',
              color: '#1B76D4'
            }}
            component="a"
            color="#1B76D4"
            href={solutionSet.enhancementReleaseGuide}
            target="_blank"
          >
            Aloha Solution v
            {solutionSet.name.substring(0, 2)}
            .
            {solutionSet.name.substring(2, 3)}
            {' '}
            Enhancement Release Guide
          </Typography>
          <Typography
            sx={{
              cursor: 'pointer',
              color: '#1B76D4'
            }}
            component="a"
            color="#1B76D4"
            href={solutionSet.releaseContentProfile}
            target="_blank"
          >
            Aloha Solution v
            {solutionSet.name.substring(0, 2)}
            .
            {solutionSet.name.substring(2, 3)}
            {' '}
            Release Content Profile
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  )
}
