import React, { useEffect, useState } from 'react'
import { Box, Stack, Typography } from '@mui/material'
import { connect } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { AppThunkDispatch, RootState } from '../../redux'
import { BreadcrumbNavigation, BreadcrumbSegment } from '../../components/BreadcrumbNavigation'
import { breadcrumbDispatchHelper } from '../../components/BreadcrumbNavigation/breadcrumbNavigationHelpers'
import { setRefreshRate, setUpgradeMonitoringBreadcrumbs } from '../../redux/upgradeMonitoring/slice'
import DeviceMonitoringTable from './components/deviceMonitoringTable'
import { Dropdown, Option } from '../../components/Dropdown'
import { refreshOptions } from '../../common/constants/refreshRate'

function mapDispatchToProps(dispatch: AppThunkDispatch) {
  return {
    dispatchSetUpgradeMonitoringBreadcrumbs: (...args: Parameters<typeof setUpgradeMonitoringBreadcrumbs>) => dispatch(setUpgradeMonitoringBreadcrumbs(...args)),
    dispatchSetRefreshRate: (...args: Parameters<typeof setRefreshRate>) => dispatch(setRefreshRate(...args))
  }
}

function mapStateToProps(state: RootState) {
  return {
    user: state.user,
    company: state.company,
    breadcrumbs: state.upgradeMonitoring.breadcrumbs,
    selectedSite: state.upgradeMonitoring.selectedSite,
    refershRate: state.upgradeMonitoring.refreshRate
  }
}

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = ReturnType<typeof mapDispatchToProps>

type UpgradeMonitoringDevicesProps = StateProps & DispatchProps

export function UpgradeMonitoringDevices({
  breadcrumbs,
  dispatchSetUpgradeMonitoringBreadcrumbs,
  user,
  selectedSite,
  dispatchSetRefreshRate,
  refershRate
}: UpgradeMonitoringDevicesProps): JSX.Element {
  const [breadcrumbDepth] = useState<number>(2)

  const currentSegment: BreadcrumbSegment = {
    name: 'Devices',
    path: '/monitoring/devices',
    order: breadcrumbDepth,
    testId: 'DevicesMonitoring'
  }

  useEffect(() => {
    breadcrumbDispatchHelper(
      breadcrumbs,
      currentSegment,
      (segments) => { dispatchSetUpgradeMonitoringBreadcrumbs(segments) }
    )
  }, [breadcrumbDepth])

  if (!user.isAdmin) return <h1>Unauthorized Access</h1>
  if (selectedSite == null) return <Navigate to="/monitoring" replace />

  return (
    <Stack
      alignItems="baseline"
      spacing={4}
    >
      <BreadcrumbNavigation breadcrumbs={breadcrumbs} />
      <Typography variant="h4" data-testid="UpgradeMonitoringDevicesHeader">
        { `${selectedSite.siteName} Devices` }
      </Typography>
      <Box
        width="100%"
        sx={{
          display: 'flex',
          flexDirection: 'row-reverse',
          p: 1,
          m: 1
        }}
      >
        <Dropdown
          width={100}
          options={refreshOptions}
          value={refershRate}
          label="Refresh Rate"
          onChange={(option: Option) => dispatchSetRefreshRate(option)}
        />
      </Box>
      <DeviceMonitoringTable />
    </Stack>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(UpgradeMonitoringDevices)
