import React from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'

export interface Option {
  label: string
  value: number
}

export interface DropdownProps {
  /** The display width of the table */
  width: number | string
  /** Dropdown options displayed to user */
  options: Option[]
  /** Dropdown default value selected */
  defaultValue?: Option
  /** Controlled value (if provided, takes precedence over defaultValue) */
  value?: Option
  /** Label for the Dropdown */
  label: string
  /** Called when the user selects an option */
  onChange?: (option: Option) => void
}

export function Dropdown({
  width,
  options,
  defaultValue,
  value,
  label,
  onChange
}: DropdownProps) {
  return (
    <Autocomplete
      disablePortal
      disableClearable
      options={options}
      defaultValue={defaultValue}
      getOptionLabel={(option: Option) => `${option.label}`}
      value={value}
      onChange={(event, newValue) => {
        if (newValue && onChange) {
          onChange(newValue)
        }
      }}
      renderInput={(params) => <TextField {...params} label={label} variant="standard" />}
      sx={{
        width: { width }
      }}
    />
  )
}

export const defaultDropdownValue: Option = { label: 'None', value: -1 }

Dropdown.defaultProps = {
  defaultValue: defaultDropdownValue,
  value: undefined,
  onChange: () => {
    // do nothing
  }
}
