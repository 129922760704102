import { AuthenticatedUser } from '../common/authenticatedUser'
import { AppError } from '../common/result'
import { get } from '../common/upgradesApiClient'

export interface ProductInfo {
  /** The url of the full release notes */
  fullReleaseNotes: string;
  /** The release notes for the current version */
  versionReleaseNotes: string;
  /** Shortname ex. RAL/ATG */
  shortName: string;
  /** Name of the product */
  productName: string;
  /** Type of release 0(Regular)/ 1(Early Maintainance)/ 2(Early Feature ) */
  releaseType: number;
  /** Verion of the product */
  version: string;
  /** Id of the product */
  productId: string;
}

export async function getProductReleaseNotes(
  productId: string,
  productVersion: string,
  authUser: AuthenticatedUser
): Promise<ProductInfo> {
  const response = await get<ProductInfo>(
    {
      route: `products/${productId}?version=${productVersion}`,
      user: authUser
    }
  )

  if (response instanceof AppError) {
    throw new Error(response.errorMsg)
  }

  return response
}

/**
 * Retrieves all available versions for a particular product
 * @param productId - product ID of desired product for retrieval of all available versions
 * @param companyId - user selected Company ID
 * @param authUser - Basic authenticated user info
 */
export async function getProductVersions(
  productId: string,
  companyId: string,
  authUser: AuthenticatedUser
): Promise<string[]> {
  const response = await get<string[]>(
    {
      route: `/products/${productId}/versions`,
      user: authUser,
      optionalHeaders: {
        companyid: companyId
      }
    }
  )

  if (response instanceof AppError) {
    throw new Error(response.errorMsg)
  }

  return response
}
