import { SolutionSet } from '../data/solutionSet'

/** This function checks if the solution
 * set name already exist in the saved solution sets
 * @returns if the solution set name entered is unique or not
 * */

export function isUnique(setName: string, solutionSets: SolutionSet[]) {
  const isUniqueName = solutionSets.every(
    (ss) => ss.name.toLocaleLowerCase() !== setName.trim().toLowerCase()
  )
  return isUniqueName
}
