import React, { useState, useEffect } from 'react'
import {
  GridCellParams,
  GridSelectionModel
} from '@mui/x-data-grid-pro'
import { connect } from 'react-redux'
import { Navigate, useNavigate } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import { Stack, TextField, Button } from '@mui/material'
import { v4 } from 'uuid'
import {
  saveSolutionSet,
  setEditableSelectedSolutionSet,
  setEditableSolutionSetBreadcrumbs
} from '../../redux/solutionSets/actions'
import { AppThunkDispatch, RootState } from '../../redux'
import { dateToString } from '../../utils/date'

import { getFriendlyProductName, SolutionSet } from '../../data/solutionSet'
import EditSolutionSetDrawer from './components/EditSolutionSetDrawer'
import { isUnique } from '../../utils/solutionSet'
import ConfirmationDialog from './ConfirmationDialog'
import { ProductInfo } from '../../data/products'
import { buildAuthenticatedUserFromState } from '../../common/authenticatedUser'
import BreadcrumbNavigation, { BreadcrumbSegment } from '../../components/BreadcrumbNavigation'
import EditSolutionSetTable from './components/editSolutionSetTable'

export interface SelectedProductState {
  /** The id of the product that is selected */
  id: string;
  /** The name of the product that is selected */
  name: string;
  /** The friendlyName of the product that is selected */
  friendlyName: string;
  /** The version of the product that is selected */
  version: string;
  /** Flag for early release version */
  earlyRelease?: boolean;
}
export interface SelectedProduct {
  /** Official state of an aloha product within a solution set; state is not expected to change unless selected product changes */
  officialState: SelectedProductState;
  /** Custom state of an aloha product within a solution set; state changes based on user selection within a solution set */
  customState: SelectedProductState;
}

function mapStateToProps(state: RootState) {
  return {
    selectedEditableSolutionSet: state.solutionSets.editableSelectedSolutionSet,
    solutionSets: state.solutionSets,
    user: state.user,
    company: state.company,
    breadcrumbs: state.solutionSets.solutionSetBreadcrumbs
  }
}
function mapDispatchToProps(dispatch: AppThunkDispatch) {
  return {
    dispatchSetEditableSelectedSolutionSet: (...args: Parameters<typeof setEditableSelectedSolutionSet>) => dispatch(setEditableSelectedSolutionSet(...args)),
    dispatchSaveSolutionSet: (...args: Parameters<typeof saveSolutionSet>) => dispatch(saveSolutionSet(...args)),
    dispatchSetEditableSolutionSetBreadcrumbs: (...args: Parameters<typeof setEditableSolutionSetBreadcrumbs>) => dispatch(setEditableSolutionSetBreadcrumbs(...args))
  }
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type EditSolutionSetProps = StateProps & DispatchProps;

export function EditSolutionSet({
  selectedEditableSolutionSet,
  solutionSets,
  user,
  company,
  dispatchSaveSolutionSet,
  dispatchSetEditableSelectedSolutionSet,
  breadcrumbs,
  dispatchSetEditableSolutionSetBreadcrumbs
}: EditSolutionSetProps) {
  const navigate = useNavigate()

  const [selectedProducts, setSelectedProducts] = useState<string[]>([])
  const [setName, setSetName] = useState<string>(
    selectedEditableSolutionSet ? selectedEditableSolutionSet.name : ''
  )
  const [uniqueNamePrompt, setUniqueNamePrompt] = useState(false)
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  const [selectedProduct, setSelectedProduct] = useState<SelectedProduct | null>(null)
  const [saveButtonEnabled, setToggleSaveButton] = useState(false)

  // ! Note: Clear the editable solution set once the user leaves the page. (Get's fired after navigate('/<route>') is invoked)
  useEffect(() => () => {
    dispatchSetEditableSelectedSolutionSet(null)
  }, [dispatchSetEditableSelectedSolutionSet])
  useEffect(() => () => {
    dispatchSetEditableSolutionSetBreadcrumbs([])
  }, [dispatchSetEditableSolutionSetBreadcrumbs])

  // * Set the breadcrumbs for the page. (Consider adding the segments to the state if we add more pages)
  const breadcrumbSegments: BreadcrumbSegment[] = [
    {
      name: 'SOLUTION SET',
      path: '/solutionsets',
      order: 0,
      testId: 'editSolutionSetBreadcrumbs'
    },
    {
      name: selectedEditableSolutionSet?.name ?? '',
      path: `/solutionsets/${selectedEditableSolutionSet?.name ?? ''}`,
      order: 1
    }
  ]

  useEffect(() => {
    // ! Use the breadcrumbNavigationHelpers function when more child pages are added and need breadcrumbs
    dispatchSetEditableSolutionSetBreadcrumbs(breadcrumbSegments)
  }, [company.selectedCompany?.id])

  const { selectedCompany } = company

  // Will force the user back to the solution sets page if this is null
  // Also will appropriately type selectedEditableSolutionSet, avoiding future
  // null checks
  if (selectedEditableSolutionSet === null) {
    return <Navigate to="/solutionsets" replace />
  }

  /** Handle Confirmation Dialog Prompt functions */
  const handleOnCancelPrompt = () => {
    setOpenDialog(false)
  }

  const handleOnConfirmPrompt = (productInfo: ProductInfo) => {
    const { products } = selectedEditableSolutionSet
    if (products && selectedProduct) {
      const newProductsState = products.map((product) => ({
        ...product,
        version:
          product.name === selectedProduct.customState.name
            ? productInfo.version
            : product.version,
        earlyRelease:
          product.name === selectedProduct.customState.name
            ? productInfo.releaseType !== 0
            : product.earlyRelease
      }))
      dispatchSetEditableSelectedSolutionSet({
        ...selectedEditableSolutionSet,
        products: newProductsState
      })
      setSelectedProduct({
        ...selectedProduct,
        customState: {
          ...selectedProduct.customState,
          earlyRelease: productInfo.releaseType !== 0,
          version: productInfo.version
        }
      })
    }
    setOpenDialog(false)
  }

  const handleOnCellClick = (params: GridCellParams) => {
    if (params.field === '__check__') return
    const { editableSelectedSolutionSet } = solutionSets
    if (!editableSelectedSolutionSet) return

    const { products } = editableSelectedSolutionSet
    const officialProduct = products.filter(
      (p) => p.name === params.row.name
    )[0]

    setSelectedProduct({
      officialState: {
        id: officialProduct.name,
        name: officialProduct.name,
        friendlyName: getFriendlyProductName(officialProduct.name),
        version: officialProduct.version
      },
      customState: params.row
    })
    setDrawerOpen(true)
  }

  const handleSelectionModelChange = (model: GridSelectionModel) => {
    // p is the row ids, id is mapped to product.name
    const { products } = selectedEditableSolutionSet
    const selectedProducts = products.filter((pkg) => model.includes(pkg.name)).map((p) => p.name)
    setSelectedProducts(selectedProducts)
    setToggleSaveButton(selectedProducts.length > 0)
  }

  const handleChangeVersionButtonClick = () => {
    setOpenDialog(true)
  }

  return (
    <>
      <Stack
        alignItems="baseline"
        paddingBottom={3}
        spacing={3}
        sx={{
          width: '600px'
        }}
      >

        <BreadcrumbNavigation breadcrumbs={breadcrumbs} />

        <Typography variant="h4">
          Customize Solution Set:
          {' '}
          {selectedEditableSolutionSet?.name}
        </Typography>
        <Typography variant="body1">
          Rename your version of this stack, choose the solutions to include in
          this stack, and add add-ons. Some solutions cannot be excluded. Some
          solutions must be included together.
        </Typography>
        <TextField
          onChange={(e) => {
            setSetName(e.target.value)
          }}
          label="Name of Stack"
          variant="standard"
          value={setName}
          helperText={
            uniqueNamePrompt
              ? 'Only able to save a solution set with a unique name'
              : ''
          }
          error={uniqueNamePrompt}
        />
      </Stack>

      <Stack width="800px">
        <Typography variant="h5">Products in Stack</Typography>
        <EditSolutionSetTable
          selectedProduct={selectedProduct}
          onCellClick={(p) => handleOnCellClick(p)}
          onGridSelectionModelChange={(model) => handleSelectionModelChange(model)}
          onCHangeVersionButtonClick={() => handleChangeVersionButtonClick()}
        />

        <Stack
          direction="row"
          width="100%"
          justifyContent="space-between"
          paddingTop={3}
        >
          <Button
            data-testid="editSolutionSetCancelButton"
            variant="outlined"
            color="primary"
            onClick={() => {
              navigate('/solutionsets')
            }}
          >
            Discard changes
          </Button>

          <Button
            data-testid="editSolutionSetSaveButton"
            variant="contained"
            color="primary"
            onClick={() => {
              const isUniqueName = isUnique(
                setName,
                solutionSets.availableSavedSolutionSets
              )

              if (isUniqueName === false) {
                setUniqueNamePrompt(true)
                return
              }

              const solutionSet: SolutionSet = {
                id: v4(),
                name: setName,
                // TODO
                description: selectedEditableSolutionSet
                  ? selectedEditableSolutionSet.description
                  : '',
                date: dateToString(new Date()),
                products: selectedEditableSolutionSet.products.filter((p) => selectedProducts.includes(p.name)),
                type: selectedEditableSolutionSet.type,
                enhancementReleaseGuide:
                  selectedEditableSolutionSet.enhancementReleaseGuide,
                releaseContentProfile:
                  selectedEditableSolutionSet.releaseContentProfile
              }
              if (user.dataCenter && company.selectedCompany?.id && user.sessionId && user.userId) {
                dispatchSaveSolutionSet(
                  solutionSet,
                  user.dataCenter,
                  company.selectedCompany.id,
                  user.sessionId,
                  user.userId
                )
              }
              navigate('/solutionsets')
            }}
            disabled={!saveButtonEnabled}
          >
            Save
          </Button>
        </Stack>
      </Stack>

      {/* Drawer */}
      {selectedProduct && (
        <EditSolutionSetDrawer
          openDrawer={drawerOpen}
          closeDrawer={() => {
            setDrawerOpen(false)
          }}
          selectedProduct={selectedProduct}
          userState={{
            company: selectedCompany,
            authUser: buildAuthenticatedUserFromState(user)
          }}
          dialogState={{
            openDialog,
            setOpenDialog,
            handleOnCancelPrompt,
            handleOnConfirmPrompt
          }}
        />
      )}

      {/* Confirmation Dialog */}
      {selectedProduct && (
        <ConfirmationDialog
          open={openDialog}
          selectedProduct={selectedProduct}
          selectedCompany={selectedCompany}
          authUser={buildAuthenticatedUserFromState(user)}
          onCancel={() => handleOnCancelPrompt()}
          onConfirm={handleOnConfirmPrompt}
        />
      )}
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(EditSolutionSet)
