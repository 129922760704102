import React, { useEffect, useState } from 'react'
import DialogBox from '../../../components/DialogBox'
import { Company } from '../../../data/companyData'
import {
  getProductReleaseNotes,
  getProductVersions,
  ProductInfo
} from '../../../data/products'
import { shortHandName } from '../../../utils/stringUtil'
import { SelectedProduct } from '..'
import { FirstVersionModalAction } from './FirstVersionModal'
import {
  SecondVersionModalAction,
  SecondVersionModalContent
} from './SecondVersionModal'
import { AuthenticatedUser } from '../../../common/authenticatedUser'

interface VersionConfirmationDialogProps {
  /** The state of whether this dialog box is open or not */
  open: boolean;
  /** What to perform when this dialog box is cancelled */
  onCancel: () => void;
  /** What to perform when this dialog box is confirmed */
  onConfirm: (productInfo: ProductInfo) => void;
  /** User selected product within a solution set */
  selectedProduct: SelectedProduct;
  /** Current user selected Company */
  selectedCompany: Company | null;
  /** Current user session info */
  authUser: AuthenticatedUser
}

/** Dialog Component; Prompt that opens that allows for user selection for changing versions of a particular product */
export default function VersionConfirmationDialogBox({
  open,
  onCancel,
  onConfirm,
  selectedProduct,
  selectedCompany,
  authUser
}: VersionConfirmationDialogProps) {
  const { officialState, customState } = selectedProduct
  const [modalIndex, setModalIndex] = useState(0)
  const [versionValue, setVersionValue] = useState<string>(customState.version)
  const [loading, setLoading] = useState<boolean>(false)
  const [productVersions, setProductVersions] = useState<Array<string>>([])

  const handleNext = async () => {
    if (selectedProduct && selectedCompany && authUser.dataCenter) {
      const getVersions = async () => {
        const data = await getProductVersions(
          selectedProduct.officialState.id,
          selectedCompany.id,
          authUser
        )
        setProductVersions(data)
      }
      setLoading(true)
      await getVersions()
      setLoading(false)
      setModalIndex(modalIndex + 1)
    }
  }

  const handleConfirmation = async () => {
    setLoading(true)
    const productInfo = await getProductReleaseNotes(
      customState.id,
      versionValue,
      authUser
    )
    setLoading(false)
    onConfirm(productInfo)
  }

  const handleCancel = () => {
    onCancel()
  }

  /** Resets versionValue and sets versionValue within range of selection */
  useEffect(() => {
    setVersionValue(customState.version)
  }, [selectedProduct])

  /** Resets Confirmation Dialog to the first screen when open state changes */
  useEffect(() => () => {
    setModalIndex(0)
  }, [open])

  const modalIndexInfo: {
    title: string;
    description: string;
    content?: JSX.Element;
    action: JSX.Element;
  }[] = [
    {
      title: 'Are you sure you want to deviate from the approved set?',
      description:
        'NCR performs solution integration testing only against approved solution sets to ensure product compatibility. Deviating from the approved set is not recommended.',
      action: (
        <FirstVersionModalAction
          loading={loading}
          onCancel={() => handleCancel()}
          onConfirm={() => handleNext()}
        />
      )
    },
    {
      title: 'Choose Version',
      description: `Choose a different version of ${shortHandName(
        officialState.friendlyName
      )} 
      to include in your solution set. The approved version is ${
  officialState.version
}`,
      content: (
        <SecondVersionModalContent
          modalContentForm={{
            selectedProduct,
            versionValue,
            versions: productVersions
          }}
          setVersionValue={setVersionValue}
        />
      ),
      action: (
        <SecondVersionModalAction
          loading={loading}
          disableConfirm={selectedProduct.customState.version === versionValue}
          onClickCancel={() => handleCancel()}
          onClickConfirm={() => handleConfirmation()}
        />
      )
    }
  ]

  return (
    <DialogBox
      open={open}
      title={modalIndexInfo[modalIndex].title}
      description={modalIndexInfo[modalIndex].description}
      data-testid="choose-version-dialog-box"
      content={modalIndexInfo[modalIndex].content}
      actions={modalIndexInfo[modalIndex].action}
    />
  )
}
