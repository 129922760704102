import { AppError } from '../common/result'
import { get, post, remove } from '../common/upgradesApiClient'
import { AuthenticatedUser } from '../common/authenticatedUser'

export const Products: {
  [key: string]: { acronym: string; friendlyName: string };
} = {
  'aloha-pos': { acronym: 'POS', friendlyName: 'Aloha Point of Sale (POS)' },
  'aloha-edc': {
    acronym: 'EDC',
    friendlyName: 'Electronic Draft Capture (EDC)'
  },
  'aloha-atg': {
    acronym: 'ATG',
    friendlyName: 'Aloha Transaction Gateway (ATG)'
  },
  'aloha-fps': { acronym: 'FPS', friendlyName: 'Fingerprint Solution (FPS)' },
  'aloha-kitchen': { acronym: 'AK', friendlyName: 'Aloha Kitchen (AK)' },
  'aloha-orderpoint': { acronym: 'OP', friendlyName: 'OrderPoint! (OP)' },
  'aloha-takeout': { acronym: 'ATO', friendlyName: 'Aloha Takeout (ATO)' },
  ral: { acronym: 'RAL', friendlyName: 'Remote Auto Loader (RAL)' }
}

export const sortedProductsList = [
  'aloha-pos',
  'aloha-edc',
  'aloha-atg',
  'aloha-kitchen',
  'aloha-orderpoint',
  'aloha-takeout',
  'aloha-fps',
  'ral'
]

/**
 * Returns the friendly product name for a given shorthand product name/identifier
 * Ex: aloha-pos => Aloha Point of Sale (POS)
 *
 * If a friendly name cannot be found for the provided argument, then the argument will
 * be returned.
 *
 * @param productName the "unfriendly" product name
 * @returns the friendly product name if found, otherwise the unfriendly product name
 */
export function getFriendlyProductName(productName: string): string {
  return Products[productName]?.friendlyName || productName
}

/**
 * Returns the friendly product name for a given shorthand product name/identifier
 * Ex: aloha-pos => Aloha Point of Sale (POS)
 *
 * If a friendly name cannot be found for the provided argument, then the argument will
 * be returned.
 *
 * @param productName the "unfriendly" product name
 * @returns the friendly product name if found, otherwise the unfriendly product name
 */
export function getProductAcronym(productName: string): string {
  return Products[productName]?.acronym || productName
}

/**
 * The definition the product inside a Solution Set
 */
export interface Product {
  /** The name of the product Ex: aloha-pos */
  name: string;
  /** The version of the product Ex: 1.0.0 */
  version: string;
  /** The link to release notes */
  releaseNotesUrl: string;
  /** Flag for early release version */
  earlyRelease?: boolean;
}

/**
 * The definition of a Solution Set
 */
export interface SolutionSet {
  /** Unique identifier of solution set */
  id: string;
  /** The name of the Solution Set */
  name: string;
  /** The description of the Solution Set */
  description: string;
  /**
   * The date that is associated with this solution set, either
   * when saved solution sets were saved, or when NCR ones were
   * released.
   */
  date: string;
  /**
   * The type of this solution set
   */
  type: string;
  /**
   * A link to this solution set's Enhancement Release Guide
   */
  enhancementReleaseGuide: string;
  /**
   * A link to this solution set's Release Content Profile
   */
  releaseContentProfile: string;
  /** An array of products that make up this Solution Set */
  products: Product[];
}

/**
 * API Response for products found in fetching solution sets
 */
interface ProductsAPIResponse {
  'aloha-atg'?: string;
  'aloha-edc'?: string;
  'aloha-fps'?: string;
  'aloha-kitchen'?: string;
  'aloha-orderpoint'?: string;
  'aloha-pos'?: string;
  'aloha-takeout'?: string;
  ral?: string;
}
/**
 * API Response for fetching solution sets
 */
interface SolutionSetAPIResponse {
  id: string;
  name: string;
  date: string;
  type: 'Long-Term Service Release' | 'Feature Release';
  description: string;
  products: ProductsAPIResponse;
  enhancementReleaseGuide: string;
  releaseContentProfile: string;
  restrictions: {
    company: string[];
    dataCenter: string[];
  };
}

/**
 * Sends a GET request to fetch the latest available NCR Solution Sets,
 * that have gone through thorough testing and been approved.
 */
export async function fetchAvailableNCRSolutionSets(
  authUser: AuthenticatedUser,
  companyId: string
) {
  const response = await get<SolutionSetAPIResponse[]>(
    {
      route: 'solutionset/ncr',
      user: authUser,
      optionalHeaders: {
        companyid: companyId
      }
    }
  )

  if (response instanceof AppError) {
    throw new Error(response.errorMsg)
  }

  const ncrSolutionSets: SolutionSet[] = response.map(
    (ss: SolutionSetAPIResponse): SolutionSet => ({
      id: ss.id,
      name: ss.name,
      date: ss.date,
      type: ss.type,
      description: ss.description,
      enhancementReleaseGuide: ss.enhancementReleaseGuide,
      releaseContentProfile: ss.releaseContentProfile,
      products: Object.entries(ss.products).map(([productName, version]) => ({
        name: productName,
        version,
        releaseNotesUrl: ''
      }))
    })
  )
  return ncrSolutionSets
}

/**
 * Sends a GET request to fetch the current user's Saved Solution Sets for a given
 * data center and company.
 *
 * @param dataCenter The data center that the user is logged into
 * @param companyId The company's id that the user wants to fetch solution sets for
 */
export async function fetchSavedSolutionSets(
  authenticatedUser: AuthenticatedUser,
  companyId: string
): Promise<SolutionSet[]> {
  const solutionSetsResponse = await get<SolutionSetAPIResponse[]>(
    {
      route: 'solutionset',
      user: authenticatedUser,
      optionalHeaders: {
        companyid: companyId
      }
    }
  )
  if (solutionSetsResponse instanceof AppError) throw solutionSetsResponse
  return solutionSetsResponse.map<SolutionSet>(
    (ss: SolutionSetAPIResponse): SolutionSet => ({
      id: ss.id,
      name: ss.name,
      date: ss.date,
      type: ss.type,
      description: ss.description,
      enhancementReleaseGuide: ss.enhancementReleaseGuide,
      releaseContentProfile: ss.releaseContentProfile,
      products: Object.entries(ss.products).map(([productName, version]) => ({
        name: productName,
        version,
        releaseNotesUrl: ''
      }))
    })
  )
}

/**
 * Sends a POST request to update the current list of Saved Solution Sets with a new
 * solution set added to the list. The provided parameter is the solution set to add
 *
 * @param solutionSet The Solution Set to save/add to the list of the user's current bank of
 * @param dataCenter The data center that the user is logged into
 * @param companyId The company's id that the user wants to save solution sets to
 */
export async function updateSavedSolutionSets(
  solutionSet: SolutionSet,
  authUser: AuthenticatedUser,
  companyId: string
): Promise<unknown> {
  const products: {
    [key: string]: string;
  } = {}
  solutionSet.products.forEach((product) => {
    products[product.name] = product.version
  })

  const solutionSetReq = {
    ...solutionSet,
    products
  }

  // Note: the backend returns an AppError<void> object,
  const response = await post(
    {
      route: '/solutionset',
      user: authUser,
      body: solutionSetReq,
      optionalHeaders: {
        companyid: companyId
      }
    }
  )

  if (response instanceof AppError) {
    throw new Error(response.errorMsg)
  }
  return response
}

/**
 * Sends a DELETE request to remove a solution set from the company's list of Saved Solution Sets
 *
 * @param solutionSet The Solution Set to delete from the user's current bank of saved solution
 * sets
 * @param dataCenter The data center that the user is logged into
 * @param companyId The company's id that the user wants to remove a solution set from
 */
export async function deleteSavedSolutionSet(
  solutionSet: SolutionSet,
  authUser: AuthenticatedUser,
  companyId: string
): Promise<void> {
  const response = await remove(
    {
      route: `/solutionset/${solutionSet.id}`,
      user: authUser,
      optionalHeaders: {
        companyid: companyId
      }
    }
  )

  if (response instanceof AppError) {
    throw new Error(response.errorMsg)
  }
}
