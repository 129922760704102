import React, { useState, MouseEvent } from 'react'
import { Box, Stack } from '@mui/material'
import {
  DataGridPro,
  GridCallbackDetails,
  GridColDef,
  GridEventListener,
  GridEvents,
  GridInitialState,
  GridRowHeightParams,
  GridRowHeightReturnValue,
  GridSelectionModel
} from '@mui/x-data-grid-pro'
import TableToolbar, {
  GridToolbarSearchBoxProps,
  SeeAllButtonProps,
  TableFooterButton
} from './TableToolbar'

export interface TableProps {
  /** The display width of the table */
  width: number | string;
  /** The rows to display in the table */
  rows: Array<object>;
  /** The columns definition of this table */
  columns: GridColDef[];
  /** The number of rows to show */
  rowsToShow: number;
  /** The options for how many rows to show */
  rowsToShowOptions: number[];
  /** An event handler for when a row is clicked on this table */
  onRowClick?: GridEventListener<GridEvents.rowClick>;
  /** An event handler for when a cell is clicked on this table */
  onCellClick?: GridEventListener<GridEvents.cellClick>;
  /** Custom configuration to load this table with a initial state */
  initialState?: GridInitialState;
  /**
   * Enables the Toolbar for this table, comes with the default toolbar
   * options from DataGridPro component, as well as a custom search bar,
   * allowing for filtering by string comparison for all columns
   */
  toolbar?: boolean;
  /** Enables a footer for this table, which can be used to add
   *  extra options for the table footer
   *  */
  footer?: boolean;
  /**
   * Custom no rows message
   */
  noRowsMessage?: string;
  /** Displays a loading circle on top of the table */
  loading?: boolean;
  /**
   * Allow for selection of rows through checkbox
   */
  checkboxSelection?: boolean;
  /**
   * An event handler for when a row is selected on this table
   */
  onSelectionModelChange?: (
    selectionModel: GridSelectionModel,
    details: GridCallbackDetails
  ) => void;
  /**
   * Allows selection to be disabled upon clicking anywhere on the row that isn't the checkbox.
   * Useful for when cells contain interactive content.
   * Will only allow selection changes upon clicking the checkbox.
   */
  disableSelectionOnClick?: boolean;
  /**
   * An event handler for managing an individual row's height on this table
   */
  getRowHeight?: (params: GridRowHeightParams) => GridRowHeightReturnValue;

  /**
   * Custom handler for the See All button
   */
  onSeeAllClick?: (e: MouseEvent) => void;
  /**
   * The text to display on the See All button
   */
  seeAllText?: string;
}

// function to override the default "No Rows" message
function CustomNoRowsOverlay(message: string | undefined) {
  return (
    <Stack height="100%" alignItems="center" justifyContent="center">
      {`${message || 'No Rows'}`}
    </Stack>
  )
}

/**
 * A custom wrapper around the DataGridPro component with row based styling
 * and pagination options already preset. The table will dynamically determine
 * it's height based on the number of rows to show.
 */
export function Table({
  width,
  rows,
  columns,
  rowsToShow,
  rowsToShowOptions,
  onRowClick,
  onCellClick,
  initialState,
  toolbar,
  footer,
  noRowsMessage,
  loading,
  checkboxSelection,
  onSelectionModelChange,
  disableSelectionOnClick,
  getRowHeight,
  onSeeAllClick,
  seeAllText
}: TableProps) {
  let disableVirtualization = false
  const [pageSize, setPageSize] = useState(rowsToShow)
  const [selectionModel, setSelectionModel] = React.useState<GridSelectionModel>([])

  // set up filtering for toolbar's search box
  const [filteredRows, setFilteredRows] = useState(rows)
  const [searchFilter, setSearchFilter] = useState('')
  const toolbarProps: GridToolbarSearchBoxProps = {
    value: searchFilter,
    clearSearch: () => {
      setSearchFilter('')
      setFilteredRows(rows)
    },
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
      const filterString = event.target.value.toLowerCase()
      const filtered = rows.filter((row) => Object.values(row).some((value) => JSON.stringify(value ?? '').toLowerCase().includes(filterString)))
      setSearchFilter(filterString)
      setFilteredRows(filtered)
    }
  }

  const getFooterProps = (): SeeAllButtonProps | undefined => {
    if (!footer) return undefined

    return {
      onClick: onSeeAllClick ?? (() => { /* Do Nothing */ }),
      buttonText: seeAllText
    }
  }

  // toolbar and footer set up based on whether or not it is enabled
  const tableComponentOptions = {
    components: {
      Toolbar: toolbar ? TableToolbar : undefined,
      Footer: footer ? TableFooterButton : undefined
    },
    props: {
      Toolbar: toolbar ? toolbarProps : undefined,
      Footer: getFooterProps()
    }
  }

  if (process.env.NODE_ENV === 'test') {
    disableVirtualization = true
  }

  return (
    <Box
      sx={{
        width, // provides space for the DataGridPro component to display within
        backgroundColor: 'white'
      }}
    >
      <DataGridPro
        // filteredRows will only differ if toolbar search box is used
        // necessary to display rows after loading and user hasn't touched search bar
        rows={searchFilter === '' ? rows : filteredRows}
        columns={columns}
        pagination
        pageSize={pageSize}
        autoHeight
        rowsPerPageOptions={rowsToShowOptions}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        checkboxSelection={checkboxSelection}
        selectionModel={selectionModel}
        onSelectionModelChange={(newSelectionModel, callbackDetails) => {
          setSelectionModel(newSelectionModel)
          if (onSelectionModelChange) {
            onSelectionModelChange(newSelectionModel, callbackDetails) // Custom defined
          }
        }}
        disableSelectionOnClick={disableSelectionOnClick}
        getRowHeight={getRowHeight}
        onRowClick={onRowClick}
        onCellClick={onCellClick}
        initialState={initialState}
        sx={{
          '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
            outline: 'none'
          }
        }}
        components={{
          NoRowsOverlay: () => CustomNoRowsOverlay(noRowsMessage),
          Toolbar: tableComponentOptions.components.Toolbar,
          Footer: tableComponentOptions.components.Footer
        }}
        componentsProps={{
          toolbar: tableComponentOptions.props.Toolbar,
          footer: tableComponentOptions.props.Footer
        }}
        loading={loading}
        disableVirtualization={disableVirtualization}
        getRowId={(row) => row.id}
      />
    </Box>
  )
}

Table.defaultProps = {
  onRowClick: () => {
    // Do nothing
  },
  onCellClick: () => {
    // Do nothing
  },
  footer: undefined,
  initialState: undefined,
  toolbar: false,
  noRowsMessage: undefined,
  loading: false,
  checkboxSelection: false,
  onSelectionModelChange: undefined,
  disableSelectionOnClick: false,
  getRowHeight: undefined,
  onSeeAllClick: undefined,
  seeAllText: 'See All'
}
