import React from 'react'
import { connect } from 'react-redux'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { AppThunkDispatch, RootState } from '../../../redux'
import { removeSavedSolutionSet } from '../../../redux/solutionSets/actions'

interface OwnProps {
  /** Whether the dialog is open and ready for input */
  open: boolean;
  /** What to do when the dialog is closed */
  onClose: () => void;
}

function mapStateToProps(state: RootState) {
  return {
    solutionSets: state.solutionSets,
    user: state.user,
    company: state.company.selectedCompany
  }
}

function mapDispatchToProps(dispatch: AppThunkDispatch) {
  return {
    dispatchRemoveSavedSolutionSet: (
      ...args: Parameters<typeof removeSavedSolutionSet>
    ) => {
      dispatch(removeSavedSolutionSet(...args))
    }
  }
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type SolutionSetDialogProps = OwnProps & DispatchProps & StateProps;

export function RemoveSolutionSetDialog({
  open,
  onClose,
  solutionSets,
  user,
  company,
  dispatchRemoveSavedSolutionSet
}: SolutionSetDialogProps) {
  const selectedSolutionSetName = solutionSets.selectedSolutionSet?.name
  const dialogTitle = `Remove Solution Set ${selectedSolutionSetName || ''}`

  return (
    <Dialog
      open={open}
      PaperProps={{
        sx: {
          borderRadius: '4px'
        }
      }}
      data-testid="remove-solution-set-dialog"
    >
      <DialogTitle>{dialogTitle}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to remove this solution set from your saved
          solution sets? You will not be able to deploy it again. Completed
          deployments will not change.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="error"
          onClick={() => {
            if (
              solutionSets.selectedSolutionSet
              && user.dataCenter
              && company?.id
              && user.sessionId
              && user.userId
            ) {
              dispatchRemoveSavedSolutionSet(
                solutionSets.selectedSolutionSet,
                user.dataCenter,
                company.id,
                user.sessionId,
                user.userId
              )
            }
            onClose()
          }}
          data-testid="remove-solution-set-dialog-remove-button"
        >
          Remove
        </Button>
        <Button
          onClick={() => {
            onClose()
          }}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RemoveSolutionSetDialog)
