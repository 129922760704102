import React from 'react'
import {
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
  GridSelectionModel
} from '@mui/x-data-grid-pro'
import { connect } from 'react-redux'
import { Table } from '../../../components/Table'
import { ChangeVersionButton } from './ChangeVersionButton'
import { getFriendlyProductName } from '../../../data/solutionSet'
import { RootState } from '../../../redux'
import { SelectedProduct, SelectedProductState } from '..'

interface OwnProps {
  selectedProduct: SelectedProduct | null
  onCellClick: (params: GridCellParams) => void
  onGridSelectionModelChange: (model: GridSelectionModel) => void
  onCHangeVersionButtonClick: () => void
}

function mapStateToProps(state: RootState) {
  return {
    selectedEditableSolutionSet: state.solutionSets.editableSelectedSolutionSet
  }
}

type StateProps = ReturnType<typeof mapStateToProps>
type EditSolutionSetTableProps = OwnProps & StateProps

export function EditSolutionSetTable(
  {
    selectedProduct,
    selectedEditableSolutionSet,
    onCellClick,
    onGridSelectionModelChange,
    onCHangeVersionButtonClick
  }: EditSolutionSetTableProps
) {
  const columns: GridColDef[] = [
    {
      field: 'friendlyName',
      headerName: 'Name',
      flex: 1,
      resizable: false
    },
    {
      field: 'version',
      headerName: 'Version',
      flex: 1,
      resizable: false,
      renderCell: (params: GridRenderCellParams) => (
        <>
          <p
            style={{
              color: params.row.earlyRelease ? '#C77700' : '#000000'
            }}
          >
            {params.value}
          </p>
          <span style={{ flexGrow: 1 }} />
          <ChangeVersionButton
            hidden={params.row.id !== selectedProduct?.customState?.id}
            onClick={onCHangeVersionButtonClick}
          />
        </>
      )
    }
  ]
  const rows = selectedEditableSolutionSet?.products.map((product): SelectedProductState => ({
    id: product.name, // map the row's id to the product name
    name: product.name,
    friendlyName: getFriendlyProductName(product.name),
    version: product.version,
    earlyRelease: product.earlyRelease
  }))

  return (
    <Table
      data-testid="Edit-Solution-Set-Table"
      width="100%"
      rows={rows ?? []}
      columns={columns}
      rowsToShow={10}
      rowsToShowOptions={[10, 20]}
      checkboxSelection
      disableSelectionOnClick
      onCellClick={onCellClick}
      onSelectionModelChange={onGridSelectionModelChange}
    />
  )
}

export default connect(mapStateToProps)(EditSolutionSetTable)
