import { Button, Stack } from '@mui/material'
import {
  GridColDef,
  gridDateComparator,
  GridEvents,
  GridEventListener,
  GridRenderCellParams
} from '@mui/x-data-grid-pro'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { AppThunkDispatch, RootState } from '../../../redux'
import { getAvailableNCRSolutionSets } from '../../../redux/solutionSets/actions'
import { Table } from '../../../components/Table'
import { dateToDisplayString, stringToDate } from '../../../utils/date'

function mapDispatchToProps(dispatch: AppThunkDispatch) {
  return {
    dispatchGetAvailableNCRSolutionSets: (
      ...args: Parameters<typeof getAvailableNCRSolutionSets>
    ) => dispatch(getAvailableNCRSolutionSets(...args))
  }
}

function mapStateToProps(state: RootState) {
  return {
    user: state.user,
    company: state.company,
    solutionSets: state.solutionSets
  }
}

interface OwnProps {
  /** An event handler for when a cell is selected on this table */
  onCellClick?: GridEventListener<GridEvents.cellClick>;
  width: number | string;
}
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type StateProps = ReturnType<typeof mapStateToProps>;
type NCRApprovedSolutionSetsTableProps = DispatchProps & StateProps & OwnProps;
export function NCRApprovedSolutionSetsTable({
  width,
  onCellClick,
  user,
  company,
  solutionSets,
  dispatchGetAvailableNCRSolutionSets
}: NCRApprovedSolutionSetsTableProps) {
  // Fetch the available NCR Solution sets on page load once
  useEffect(() => {
    if (user.dataCenter && company.selectedCompany?.id && user.sessionId && user.userId) {
      dispatchGetAvailableNCRSolutionSets(
        user.dataCenter,
        user.sessionId,
        user.userId,
        company.selectedCompany.id
      )
    }
  }, [company.selectedCompany?.id])

  const { availableNCRSolutionSets } = solutionSets
  // Fetch the loaded available NCR solution sets to display in table
  const rows = availableNCRSolutionSets.map((ss) => ({
    id: ss.id,
    name: ss.name,
    date: stringToDate(ss.date), // converted to Date object
    edit: 'Edit',
    deploy: 'Deploy'
  }))

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 2
    },
    {
      field: 'date',
      headerName: 'Date Released',
      type: 'date', // Allows for Date objects to be parsed
      flex: 2,
      sortComparator: gridDateComparator,
      sortingOrder: ['desc', 'asc'],
      valueFormatter: (p) => {
        if (p.value instanceof Date) {
          return dateToDisplayString(p.value)
        }
        return p.value
      }
    },
    {
      field: 'edit',
      headerName: '',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => (
        <Button
          fullWidth
          sx={{
            textDecoration: 'underline',
            '&:hover': {
              textDecoration: 'underline'
            }
          }}
        >
          Edit
          {' '}
          {params.value.id}
        </Button>
      )
    },
    {
      field: 'deploy',
      headerName: '',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => (
        <Button
          fullWidth
          sx={{
            textDecoration: 'underline',
            '&:hover': {
              textDecoration: 'underline'
            }
          }}
          data-testid="test-deploy-ncr-approved"
        >
          Deploy
          {' '}
          {params.value.id}
        </Button>
      )
    }
  ]

  return (
    <Stack
      direction="row"
      alignItems="flex-start"
      spacing={2}
      sx={{ width: '100%' }}
    >
      <Table
        width={width}
        rows={
          solutionSets.fetchNCRApprovedSolutionSetRequestState === 'pending'
            ? []
            : rows
        }
        columns={columns}
        rowsToShow={5}
        rowsToShowOptions={[5, 10, 20]}
        onCellClick={onCellClick}
        loading={
          solutionSets.fetchNCRApprovedSolutionSetRequestState === 'pending'
        }
        initialState={{
          sorting: {
            sortModel: [{ field: 'date', sort: 'desc' }]
          }
        }}
      />
    </Stack>
  )
}

NCRApprovedSolutionSetsTable.defaultProps = {
  onCellClick: () => {
    // Do nothing
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NCRApprovedSolutionSetsTable)
