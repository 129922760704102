import React from 'react'
import { GridColDef } from '@mui/x-data-grid-pro'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { RootState } from '../../../redux'
import { Table } from '../../../components/Table'

function mapStateToProps(state: RootState) {
  return {
    requestState: state.upgradeMonitoring.siteMonitoringPackageVersionsState,
    packageVersions: state.upgradeMonitoring.packageVersions
  }
}

const columns: GridColDef[] = [
  {
    field: 'id',
    headerName: 'Product',
    flex: 1
  },
  {
    field: 'latestVersion',
    headerName: 'Latest 19.6',
    flex: 1
  },
  {
    field: 'currentVersion',
    headerName: 'Installed',
    flex: 1
  },
  {
    field: 'targetVersion',
    headerName: 'Targeted',
    flex: 1
  }
]

type StateProps = ReturnType<typeof mapStateToProps>

type SiteMonitoringDrawerTableProps = StateProps

export function SiteMonitoringDrawerTable(
  {
    requestState,
    packageVersions
  }: SiteMonitoringDrawerTableProps
): JSX.Element {
  const navigate = useNavigate()
  const packageVersionRows = requestState === 'pending' || packageVersions?.packageVersions == null ? [] : packageVersions.packageVersions

  const rows = packageVersionRows.map((p) => ({
    id: p.product,
    latestVersion: p.latestVersion,
    currentVersion: p.currentVersion,
    targetVersion: p.targetVersion
  }))

  return (
    <Table
      width="100%"
      rows={rows}
      columns={columns}
      rowsToShow={10}
      rowsToShowOptions={[10]}
      loading={requestState === 'pending'}
      disableSelectionOnClick
      footer={requestState === 'fulfilled'}
      onSeeAllClick={() => { navigate('/monitoring/devices') }}
      seeAllText="See Devices"
    />
  )
}

export default connect(mapStateToProps)(SiteMonitoringDrawerTable)
