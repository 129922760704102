import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { Stack } from '@mui/material'

interface DialogBoxProps {
  /** Whether the dialog is open and ready for input */
  open: boolean;
  /** The title of this DialogBox */
  title: string;
  /** The description of this DialogBox */
  description: string;
  /** The actions presented to the user to execute and close */
  actions: JSX.Element;
  /** Components to display in the contents of the DialogBox */
  content?: JSX.Element;
  /** Test Id associated with this DialogBox */
  'data-testid'?: string;
  fullWidth?: boolean;
}

export default function DialogBox(props: DialogBoxProps) {
  return (
    <Dialog
      open={props.open}
      PaperProps={{
        sx: {
          borderRadius: '4px'
        }
      }}
      data-testid={props['data-testid']}
      fullWidth={props.fullWidth}
    >
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>
        <Stack width="100%" spacing={2}>
          <DialogContentText>{props.description}</DialogContentText>
          {props.content}
        </Stack>
      </DialogContent>
      <DialogActions>{props.actions}</DialogActions>
    </Dialog>
  )
}

DialogBox.defaultProps = {
  content: undefined,
  'data-testid': undefined,
  fullWidth: false
}
