import { Button } from '@mui/material'
import React from 'react'

interface ChangeVersionProps {
  hidden: boolean;
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

/** Change Version button displayed within the Edit Solution Sets Page table; Visible only upon selection of a row */
export function ChangeVersionButton(props: ChangeVersionProps) {
  return props.hidden ? null : (
    <Button
      sx={{
        textDecoration: 'underline',
        '&:hover': {
          textDecoration: 'underline'
        }
      }}
      onClick={props.onClick}
    >
      Change Version
    </Button>
  )
}
