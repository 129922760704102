import { CircularProgress, Stack, Typography } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { getProductReleaseNotes, ProductInfo } from '../../../data/products'
import { BasicDrawer, DrawerHeaderOptions } from '../../../components/Drawer'
import ConfirmationDialog from '../ConfirmationDialog'
import { SelectedProduct } from '..'
import { Company } from '../../../data/companyData'
import { AuthenticatedUser } from '../../../common/authenticatedUser'

interface VersionConfirmationDialogState {
  /** boolean state that is passed to controlled when the prompt opens or close */
  openDialog: boolean;
  /** Sets state of dialog prompt */
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
  /** Action performed upon cancellation of prompt */
  handleOnCancelPrompt: () => void;
  /** Action performed upon confirmation of prompt */
  handleOnConfirmPrompt: (productInfo: ProductInfo) => void;
}

interface UserState {
  company: Company | null
  authUser: AuthenticatedUser
}

interface EditSolutionSetDrawerProps {
  openDrawer: boolean;
  closeDrawer: () => void;
  selectedProduct: SelectedProduct;
  dialogState: VersionConfirmationDialogState;
  userState: UserState;
}

export default function EditSolutionSetDrawer(
  {
    selectedProduct,
    userState,
    dialogState,
    closeDrawer,
    openDrawer
  }: EditSolutionSetDrawerProps
) {
  const [productInfo, setProductInfo] = useState<ProductInfo | null>(null)

  const [loading, setLoading] = useState<boolean>(false)
  const {
    openDialog,
    setOpenDialog,
    handleOnCancelPrompt,
    handleOnConfirmPrompt
  } = dialogState

  useEffect(() => {
    const getProductInfo = async () => {
      try {
        setLoading(true)
        const data = await getProductReleaseNotes(
          selectedProduct.customState.id,
          selectedProduct.customState.version,
          userState.authUser
        )
        if (data.versionReleaseNotes !== null) {
          setProductInfo(data)
        }
      } catch (err) {
        setProductInfo(null)
      } finally {
        setLoading(false)
      }
    }
    getProductInfo()
  }, [selectedProduct])

  const type = productInfo?.releaseType

  let description = ''
  let warningLabel = ''
  if (type === 1) {
    warningLabel = 'Early Feature Release'
    description = `You have selected a maintenance version of ${selectedProduct.customState.friendlyName} that has made 
    available to you prior to the completion of our standard release cycle. Click revert to use the approved version of this product`
  } else if (type === 2) {
    warningLabel = 'Early Maintainance Release'
    description = `You have selected a maintenance version of ${selectedProduct.customState.friendlyName} that has made 
    available to you prior to the completion of our standard release cycle. Click revert to use the approved version of this product.`
  }

  const headerOptions: DrawerHeaderOptions = {
    enableCloseArrow: true,
    buttons: [
      {
        label: 'Change Version',
        onClick: () => setOpenDialog(true),
        variant: 'outlined'
      }
    ]
  }

  return (
    <>
      <BasicDrawer
        width={350}
        openDrawer={openDrawer}
        closeDrawer={closeDrawer}
        drawerHeader={headerOptions}
      >
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={4}
          paddingLeft={1.5}
          paddingTop={2}
          paddingRight={3}
        >
          <Typography variant="h6">
            {selectedProduct.customState.friendlyName}
            {' '}
            v.
            {selectedProduct.customState.version}
          </Typography>

          {type === 1 && (
            <Stack spacing={0}>
              <Typography variant="h6" color="#C77700">
                {warningLabel}
              </Typography>
              <Typography color="#C77700">{description}</Typography>
            </Stack>
          )}

          {type === 2 && (
            <Stack spacing={0}>
              <Typography variant="h6" color="#C77700">
                {warningLabel}
              </Typography>
              <Typography color="#C77700">{description}</Typography>
            </Stack>
          )}

          {loading ? (
            <CircularProgress
              style={{ flex: 1, alignSelf: 'center' }}
              size={50}
              thickness={1.0}
            />
          ) : (
            <>
              {productInfo?.versionReleaseNotes && (
                <Stack>
                  <Typography variant="caption" color="rgba(0, 0, 0, 0.6)">
                    Version Release Notes
                  </Typography>

                  <Stack paddingLeft={1}>
                    {productInfo.versionReleaseNotes
                      && productInfo.versionReleaseNotes
                        .split('[')
                        .slice(1)
                        .map((notes, index) => (
                          <Typography
                            variant="body1"
                            // eslint-disable-next-line react/no-array-index-key
                            key={index}
                          >
                            {`• [${notes}`}
                          </Typography>
                        ))}
                  </Stack>
                  <Stack paddingLeft={1} />
                </Stack>
              )}

              {productInfo?.fullReleaseNotes && (
                <Stack spacing={0}>
                  <Typography variant="caption" color="rgba(0, 0, 0, 0.6)">
                    Full Release Notes
                  </Typography>

                  <Typography
                    sx={{
                      cursor: 'pointer',
                      color: '#1B76D4'
                    }}
                    component="a"
                    color="#1B76D4"
                    href={productInfo?.fullReleaseNotes}
                    target="_blank"
                  >
                    Update and release notes for
                    {' '}
                    {productInfo?.productName}
                  </Typography>
                </Stack>
              )}
            </>
          )}
        </Stack>
      </BasicDrawer>

      {/* Confirmation Dialog */}
      {selectedProduct && (
        <ConfirmationDialog
          open={openDialog}
          selectedProduct={selectedProduct}
          selectedCompany={userState.company}
          authUser={userState.authUser}
          onCancel={() => handleOnCancelPrompt()}
          onConfirm={handleOnConfirmPrompt}
        />
      )}
    </>
  )
}
